.mm-navbar--auth .navbar .navbar-brand img,
.mm-navbar--simple .navbar .navbar-brand img {
  width: auto;
}

.sub-heading-spotlight {
  color: var(--bp-color-text-tertiary);
}

.table-headers-dates {
  color: var(--bp-color-primary-default);
}

.text-border {
    border:1px solid #DCF4FC;
    justify-content: center;
    padding-top: 5%;
    display: flex;
    text-align: center;
    background-color: #DCF4FC;
}

.text-border-links {
    padding-left: 25%;
    padding-right: 25%;
    border:1px solid #DCF4FC;
    display: inline-block;
    text-align: center;
    background-color: #DCF4FC;
}

.table-border {
  border:1px solid #DCF4FC;
  display: flex;
  background-color: #DCF4FC;
  margin-bottom: 0.5%;
}

.indent-column {
  margin-right: 50%; /* Adjust the value as needed */
}
